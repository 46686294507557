<div class="popup-overlay alert-popup-overlay" *ngIf="_AlertService.isAlertEnabled">
  <div class="popup-content-alert" id="alert-popup" clickOutside [NonCloseElementId]="['alert-popup']">
  
    <div class="cstm-model-header d-flex justify-content-between align-items-center mb-4">
      <h5 class="pe-5"><span class="text-truncate1 min205"> {{
        _AlertService.alertData.title}} </span></h5>
          <button type="button" class="close-btn" (click)="closeAlerts()"><img src="images/times--white.svg" alt="Close"></button>
    </div>
    <div class="content">
      <p>{{ _AlertService.alertData.message}}</p>
    </div>
    <div class="pt-3 text-end truncate-new d-flex flex-row-reverse">
      <button (click)="buttonClick(button)" *ngFor="let button of _AlertService.buttonConfig" type="button"
        [ngClass]="getBtnStyle(button)" class="btn btn-secondary mr-10 my-2 me-2">
        <span> {{ button.label}} </span>
      </button>
    </div>
  </div>
</div>
