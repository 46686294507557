import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CommonLoaderService {

  constructor(
  ) { }

  private loaderEnableSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public loaderEnable$ = this.loaderEnableSubject.asObservable();

  public getLoader(): number {
    return this.loaderEnableSubject.getValue();
  }

  public showLoader(): void {
    this.loaderEnableSubject.next(this.getLoader() + 1);
  }

  public resetLoader(): void {
    if (this.getLoader() > 0) {
      const currentCount = this.getLoader();
      this.loaderEnableSubject.next(currentCount - 1);
    }
  }

}
