export interface ISearchRequest {
  searchCriterias?: Array<ISearchCriteria>;
  pageIndex: Number;
  pageLimit: Number;
  sortBy?: string;
  sortOrder?: string;
  dateFilterCriteria?: IDateFilterCriteria
}

export interface IDateFilterCriteria {
  fromDate: string | null;
  toDate: string | null;
}

export interface IWildCardSearchRequest extends ISearchRequest {
  fullTextSearch?: IFullTextSearch
}

export interface IFullTextSearch {
  searchText: string;
}

export interface ISearchCriteria {
  column: string;
  value?: string;
  multipleValues?: Array<string>
  operator: SearchCriteriaOperator;
}

export enum SearchCriteriaOperator {
  LIKE = "LIKE", START_WITH = "START_WITH", END_WITH = "END_WITH", IS_NULL = "IS_NULL", IS_NOT_NULL = "IS_NOT_NULL",
  EQUAL = "EQUAL", NUMERICAL_EQUAL = "NUMERICAL_EQUAL", NOT_NUMERICAL_EQUAL = "NOT_NUMERICAL_EQUAL",
  NOT_EQUAL = "NOT_EQUAL", NOT_LIKE = "NOT_LIKE", NOT_START_WITH = "NOT_START_WITH", NOT_END_WITH = "NOT_END_WITH",
  GREATER_THAN = "GREATER_THAN", GREATER_THAN_EQUAL = "GREATER_THAN_EQUAL", LESSER_THAN = "LESSER_THAN",
  LESSER_THAN_EQUAL = "LESSER_THAN_EQUAL", IN = "IN", NOT_IN = "NOT_IN"
}
