import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (value && value.length > 0) {
      const countryCodeStr = value.slice(0, 2);
      const areaCodeStr = value.slice(2, 5);
      const midSectionStr = value.slice(5, 8);
      const lastSectionStr = value.slice(8);
      return countryCodeStr.concat(' (').concat(areaCodeStr).concat(') ').concat(midSectionStr).concat('-').concat(lastSectionStr);
    }
    return "-";
  }
}
