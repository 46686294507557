import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percent'
})
export class PercentFormatPipe implements PipeTransform {

  transform(value: any): string | null {
    if (value) {
      return new PercentPipe('en-US').transform(value, '0.0-2');
    }
    return '-';
  }

}
