import { Component, OnInit } from '@angular/core';
import { IAlertButtonStyles, IAlertButtonConfig } from '../../models/alert.model';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private readonly closeIconConfig: IAlertButtonConfig = {
    label: 'close',
    name: 'Close',
    style: IAlertButtonStyles.SECONDARY
  };
  constructor(
    public _AlertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  closeAlerts(): void {
    this.buttonClick(this.closeIconConfig);
  }

  buttonClick(config: IAlertButtonConfig): void {
    this._AlertService.emitUserSelection(config);
  }

  getBtnStyle(button: IAlertButtonConfig): string {
    switch (button.style) {
      case IAlertButtonStyles.DANGER:
        return 'btn-danger';
      case IAlertButtonStyles.PRIMARY:
        return 'btn-primary';
      case IAlertButtonStyles.SECONDARY:
        return 'btn-secondary';
      default:
        return 'btn-secondary';
    }
  }

}
