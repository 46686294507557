import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "label"
})
export class LabelFormatPipe implements PipeTransform {
  transform(value: any | undefined): string {
    if (value != undefined && value != null && value.toString().length > 0) {
      return value;
    }
    return "-";
  }
}
