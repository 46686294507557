import { Endpoints } from './../constants/endpoints';
import { ApiGatewayService } from './api-gateway.service';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from '../pages/toaster/toaster.service';
import { ToasterType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private permissions: Array<string> = [];

  constructor(
    private _Router: Router,
    private _ToasterService: ToasterService,
    private permissionService: NgxPermissionsService,
    private _ApiGatewayService: ApiGatewayService
  ) { }

  flushPermission(): void {
    this.permissionService.flushPermissions();
    this.permissions = [];
  }

  setPermissions(): Observable<any> {
    return this.getAllPermissions();

  }

  getAllPermissions(): Observable<any> {
    return new Observable(observer => {
      this.getPermissions()
        .subscribe({
          next: (response) => {
            this.permissionService.loadPermissions(response);
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.next([]);
            observer.complete();
            this.permissions = [];
            this.permissionService.loadPermissions([]);
          }
        });
    });
  }

  private getPermissions(): Observable<any> {
    let url: string = Endpoints.API_URLS.Auth.Permission;
    return new Observable(observer => {
      this._ApiGatewayService.doGet(url, { isLoaderNeeded: true, isApiErrorMsgNeeded: true, customErrorMessage: 'Permission fetch failed!' }).subscribe(resp => {
        this.permissions = resp.data;
        observer.next(resp.data);
        observer.complete();
      }, (error) => {
        observer.next([]);
        observer.complete();
        this._ToasterService.addToasters({ message: 'You are unauthorised to perform this action', type: ToasterType.ERROR });
        this._Router.navigateByUrl('/app/auth/logout');
      });
    });
  }

  hasPermission(permission: string): boolean {
    return (this.permissionService.getPermission(permission) ? true : false);
  }

  canCurrentUser(permission: string) {
    for (var key in this.permissions) {
      if (this.permissions.hasOwnProperty(key) && this.permissions[key] === permission) {
        return true;
      }
    }
    return false;
  }

}
