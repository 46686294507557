import { AppUtils } from 'src/app/modules/shared/utils/app-utils';
import { ToasterType } from './../models/toaster-type';
import { ToasterService } from './../pages/toaster/toaster.service';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { IApiConfig, IResponse } from "../models";
import { Utilities } from "../utils";
import { ApiService } from "./api.service";
import { CommonLoaderService } from "./common-loader.service";

@Injectable({
  providedIn: 'root',
})
export class ApiGatewayService {

  constructor(
    private _ApiService: ApiService,
    private _CommonLoaderService: CommonLoaderService,
    private _Router: Router,
    private _ToasterService: ToasterService
  ) { }

  /**
   * Function for HTTP POST Method
   * @author Libin
   * @param url
   * @param request
   * @param IApiConfig
   * @returns
   */
  doPost(url: string, request: any, config?: IApiConfig): Observable<any> {
    return new Observable(observer => {
      this.isLoaderNeeded(config);
      this._ApiService.post(url, request).subscribe({
        next: (res) => {
          if (this.isUnAuthorized(res)) {
            this._Router.navigateByUrl('app/auth/logout')
          } else if (this.isRedirectTemp(res)) {
            window.open(res.data.redirectUrl as string, '_blank');
            observer.next(res);
          } else if (this.isSuccess(res)) {
            this.displaySuccessToaster(res, config);
            observer.next(res);
          } else {
            observer.next(res);
            this.displayErrorToaster(res, config);
          }
          observer.complete();
        },
        error: (error) => {
          this.displayErrorToaster(error, config);
          observer.next(error);
          observer.complete();
        },
        complete: () => {
          this.resetLoader(config!);
        }
      });
    })
  }

  /**
   * Function for HTTP GET Method
   * @author Libin
   * @param url
   * @param config
   * @returns
   */
  doGet(url: string, config?: IApiConfig): Observable<any> {
    return new Observable(observer => {
      this.isLoaderNeeded(config);
      this._ApiService.get(url).subscribe({
        next: (res) => {
          if (this.isUnAuthorized(res)) {
            this._Router.navigateByUrl('app/auth/logout')
          } else if (this.isRedirectTemp(res)) {
            window.open(res.data.redirectUrl as string, '_blank');
            observer.next(res);
          } else if (this.isSuccess(res)) {
            this.displaySuccessToaster(res, config);
            observer.next(res);
          } else {
            observer.next(res);
            this.displayErrorToaster(res, config);
          }
          observer.complete();
        },
        error: (error) => {
          this.displayErrorToaster(error, config);
          observer.next(error);
          observer.complete();
        },
        complete: () => {
          this.resetLoader(config!)
        }
      });
    })
  }

  /**
   * Function for HTTP PUT Method
   * @author Libin
   * @param url
   * @param request
   * @param config
   * @returns
   */
  doPut(url: string, request: object, config?: IApiConfig): Observable<any> {
    return new Observable(observer => {
      this.isLoaderNeeded(config);
      this._ApiService.put(url, request).subscribe({
        next: (res) => {
          if (this.isUnAuthorized(res)) {
            this._Router.navigateByUrl('app/auth/logout')
          } else if (this.isSuccess(res)) {
            this.displaySuccessToaster(res, config);
            observer.next(res);
          } else {
            observer.next(res);
            this.displayErrorToaster(res, config);
          }
          observer.complete();
        },
        error: (error) => {
          this.displayErrorToaster(error, config);
          observer.next(error);
          observer.complete();
        },
        complete: () => {
          this.resetLoader(config!)
        }
      });
    })
  }

  /**
   * Function for HTTP DELETE Method
   * @author Libin
   * @param url
   * @param request
   * @param config
   * @returns
   */
  doDelete(url: string, config?: IApiConfig): Observable<any> {
    return new Observable(observer => {
      this.isLoaderNeeded(config);
      this._ApiService.delete(url).subscribe({
        next: (res) => {
          if (this.isUnAuthorized(res)) {
            this._Router.navigateByUrl('app/auth/logout')
          } else if (this.isSuccess(res)) {
            this.displaySuccessToaster(res, config);
            observer.next(res);
          } else {
            observer.next(res);
            this.displayErrorToaster(res, config);
          }
          observer.complete();
        },
        error: (error) => {
          this.displayErrorToaster(error, config);
          observer.next(error);
          observer.complete();
        },
        complete: () => {
          this.resetLoader(config!)
        }
      });
    })
  }

  /**
   * Loader config. By default need to show the loader for every api
   * @author Libin
   */
  private isLoaderNeeded(config?: IApiConfig): void {
    if (config) {
      if (config.isLoaderNeeded !== false) {
        this._CommonLoaderService.showLoader();
      }
    } else {
      this._CommonLoaderService.showLoader();
    }
  }

  /**
   * Reset loader config to default
   * @author Libin
   */
  private resetLoader(config: IApiConfig): void {
    if (config) {
      if (config.isLoaderNeeded !== false) {
        this._CommonLoaderService.resetLoader();
      }
    } else {
      this._CommonLoaderService.resetLoader();
    }
  }

  /**
   * Display success toaster as per the config
   * @author Libin
   */
  private displaySuccessToaster(response: IResponse<any>, config?: IApiConfig) {
    let msg: string = 'Request was success!';
    if (config && config.isApiSuccessMsgNeeded) {
      if (Utilities.isObjectContainsValidKey(config, 'customSuccessMessage')) {
        msg = config.customSuccessMessage!;
      } else if (Utilities.isObjectContainsValidKey(response, 'status') && Utilities.isObjectContainsValidKey(response['status'], 'developerMessage')) {
        msg = response.status.userMessage;
      }
      this._ToasterService.showToaster(ToasterType.SUCCESS, msg).subscribe();
    }
  }

  /**
   * Display error toaster as per the config
   * @author Libin
   */
  private displayErrorToaster(response: any, config?: IApiConfig) {
    let msg: string = 'Some error occured on the server side!';
    if ((config && (!config.hasOwnProperty('isApiErrorMsgNeeded') || config.isApiErrorMsgNeeded)) || (!config)) {
      if (Utilities.isObjectContainsValidKey(config, 'customErrorMessage')) {
        msg = config?.customErrorMessage!;
      } else if (Utilities.isObjectContainsValidKey(response, 'status') && Utilities.isObjectContainsValidKey(response['status'], 'developerMessage')) {
        msg = response.status.developerMessage;
      }
      this._ToasterService.showToaster(ToasterType.ERROR, msg).subscribe();
    }
  }

  private isUnAuthorized(res: any): Boolean {
    if (res && res.status && res.status.code && res.status.code == '401') {
      return true;
    }
    return false;
  }

  private isRedirectTemp(res: any): Boolean {
    if (res && res.status && res.status.code && res.status.code == '302') {
      return true;
    }
    return false;
  }

  private isSuccess(res: any): Boolean {
    return AppUtils.isApiSuccess(res);
  }

}
