export interface IAlertButtonConfig {
  name: string;
  label: string;
  style: IAlertButtonStyles;
}

export interface IAlertData {
  title: string;
  message: string;
}

export enum IAlertButtonStyles {
  PRIMARY, SECONDARY, DANGER
}
