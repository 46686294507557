import { environment } from "src/environments/environment";
export const API_HOST_NAME: string = environment.apiUrl;

export class Endpoints {
  public static API_URLS = {
    Auth: {
      Login: API_HOST_NAME + "/auth/login",
      Logout: API_HOST_NAME + "/auth/logout",
      Permission: API_HOST_NAME + "/user/role-permissions"
    },
    User: {
      CreateUser: API_HOST_NAME + "/user/create",
      SearchUser: API_HOST_NAME + "/user/search",
      DeleteUser: API_HOST_NAME + "/user/{userId}",
    },
    Shipment: {
      GetShipment: API_HOST_NAME + "/shipment/{trackingNumber}",
      UpdateShipment: API_HOST_NAME + "/shipment/{trackingNumber}",
      UpdateShipmentStatus: API_HOST_NAME + "/shipment/status/{trackingNumber}",
      DeleteShipment: API_HOST_NAME + "/shipment/{trackingNumber}",
      CreateShipment: API_HOST_NAME + "/shipment",
      SearchShipment: API_HOST_NAME + "/shipment/search",
      CustomerTrackingSearch: API_HOST_NAME + "/shipment/customer/{trackingNumber}",
      CountReport: API_HOST_NAME + "/shipment/report/count",
    },
  }
}
