import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSearch'
})
/**
 * custom search - list of object having a key(searchType)
 * and search is done in that key by user keyword(searchText)
 * */
export class CustomSearchPipe implements PipeTransform {
  transform(items: any, searchText: string, key: string): any[] {
    for (var i = 0; i < items.length; i++) {
      if (!items[i]) return [];
      if (!searchText) return items;
      searchText = searchText.toLowerCase();
      return items.filter((it: any) => {
        return it[key].toLowerCase().includes(searchText);
      });
    }
    return items;
  }
}
