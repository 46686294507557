import { IToasterData, ToasterType } from './../../models/toaster-type';
import { Component, OnInit } from '@angular/core';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  toasterType = ToasterType;

  constructor(
    public _ToasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  closeToaster(toaster: IToasterData) : void {
    this._ToasterService.closeToaster(toaster);
  }

}
