import { Component, OnInit } from '@angular/core';
import { CommonLoaderService } from '../../services';

@Component({
  selector: 'app-common-loader',
  standalone: false,
  templateUrl: './common-loader.component.html',
  styleUrl: './common-loader.component.scss'
})
export class CommonLoaderComponent implements OnInit {

  constructor(
    private _CommonLoaderService: CommonLoaderService
  ) { }

  private loaderCount: number = 0;

  ngOnInit(): void {
    this._CommonLoaderService.loaderEnable$.subscribe(count => {
      this.loaderCount = count;
    });
  }

  isLoaderNeeded(): boolean {
    return (this.loaderCount > 0);
  }

}
