import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe, PercentPipe } from '@angular/common';
import { ApiService, AlertComponent, ApiGatewayService, ApiInterceptor, CommonLoaderComponent, ErrorComponent, ToasterComponent, SafeUrlPipe, PhoneFormatPipe, LabelFormatPipe, DateFormatPipe, PercentFormatPipe, CustomSearchPipe, TimeAgoPipe, CloseDropdownOnScrollDirective, OutSideClickDirective, RightClickDirective, InputTrimDirective, FallbackImgDirective, InputNumberValidator } from './index';
import { FormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    InputTrimDirective,
    RightClickDirective,
    OutSideClickDirective,
    CloseDropdownOnScrollDirective,
    InputNumberValidator,
    FallbackImgDirective,
    ToasterComponent,
    CommonLoaderComponent,
    PhoneFormatPipe,
    SafeUrlPipe,
    CustomSearchPipe,
    LabelFormatPipe,
    DateFormatPipe,
    PercentFormatPipe,
    AlertComponent,
    ErrorComponent,
    TimeAgoPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPermissionsModule.forChild(),
    NgSelectModule,
    BsDatepickerModule,
    ClipboardModule,
    BsDropdownModule,
    TooltipModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    ApiGatewayService,
    ApiService,
    HttpClient,
    DateFormatPipe,
    PercentFormatPipe,
    PhoneFormatPipe,
    DatePipe,
    PercentPipe,
    CustomSearchPipe
  ],
  exports: [
    CommonModule,
    BsDatepickerModule,
    ClipboardModule,
    FormsModule,
    NgxPermissionsModule,
    CommonLoaderComponent,
    OutSideClickDirective,
    CloseDropdownOnScrollDirective,
    InputTrimDirective,
    RightClickDirective,
    InputNumberValidator,
    FallbackImgDirective,
    ToasterComponent,
    PhoneFormatPipe,
    SafeUrlPipe,
    CustomSearchPipe,
    DateFormatPipe,
    PercentFormatPipe,
    LabelFormatPipe,
    AlertComponent,
    ErrorComponent,
    NgSelectModule,
    TimeAgoPipe,
    BsDropdownModule,
    TooltipModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiGatewayService
      ]
    };
  }
}
