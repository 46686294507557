import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { PermissionService } from '../services';
import { AppUtils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  constructor(
    private _Router: Router,
    private _PermissionService: PermissionService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!AppUtils.isLoggedIn()) {
      this._Router.navigate(['app/auth/logout']);
      return false;
    } else {
      return this._PermissionService.setPermissions().pipe(
        map(data => true));
    }
  }

}
