import { IToasterData, ToasterType } from './../../models/toaster-type';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private toasters: Array<IToasterData> = [];
  private invokeFn: any;
  private isToasterVisible: Boolean = false;

  constructor() { }

  public getToasters(): Array<IToasterData> {
    return this.toasters;
  }

  public setToasters(toasters: Array<IToasterData>): void {
    this.toasters = toasters;
  }

  public addToasters(toasters: IToasterData): void {
    if (this.toasters.length >= 5) {
      const toaster = this.toasters.find(o => o.isAutoCloseable===true);
      if(toaster!=undefined){
        const index = this.toasters.indexOf(toaster);
        this.removeToaster(index);
      }
    }
    this.toasters.push(toasters);
  }

  public removeToaster(index: number): void {
    this.toasters.splice(index, 1);
  }

  public getIsToasterVisible(): Boolean {
    return this.isToasterVisible;
  }

  public setIsToasterVisible(isToasterVisible: Boolean): void {
    this.isToasterVisible = isToasterVisible;
  }

  /**
   *
   * @param type ToasterType
   * @param msg Message to display
   * @param timeout Timeout of the toaster. Default value is 5000 (5 sec).
   * @param isAutoCloseNeeded Boolean value. If true then toaster will disappear automatically after timeout.
   * @returns
   */
  public showToaster(type: ToasterType, msg: string, timeout: number = 5000, isAutoCloseNeeded: Boolean = true): Observable<any> {
    return new Observable(observer => {
      const toaster = { type: type, message: msg, isAutoCloseNeeded: isAutoCloseNeeded };
      this.addToasters(toaster);
      this.invokeFn = observer;
      this.setIsToasterVisible(true)
      if (isAutoCloseNeeded) {
        setTimeout(() => {
          this.setIsToasterVisible(false)
          this.closeToaster(toaster);
        }, timeout);
      }
    })
  }

  public closeToaster(toaster: IToasterData): void {
    const index = this.toasters.indexOf(toaster);
    if(index!=-1){
      this.setIsToasterVisible(false)
      this.removeToaster(index);
      if (this.toasters.length == 0) {
        this.invokeFn.next(null);
        this.invokeFn.complete();
      }
    }
  }

}
