import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class OutSideClickDirective {

  constructor(private _elementRef: ElementRef) { }

  @Input('NonCloseElementId') elementList: Array<string> = [];
  @Input('InsideCloseElementId') insideCloseElementList: Array<string> = [];
  @Output('OutsideClickEvent') clickOutsideEvent: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement: HTMLElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    const insideElementList: boolean = this.elementList.includes(targetElement.getAttribute('id')!)
    const isInsideCloseElement: boolean = this.insideCloseElementList.includes(targetElement.getAttribute('id')!);

    if (!clickedInside && !insideElementList) {
      this.clickOutsideEvent.emit(null);
    } else if (clickedInside && isInsideCloseElement) {
      this.clickOutsideEvent.emit(null);
    }
  }
}
