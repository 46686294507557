import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, filter, tap } from 'rxjs/operators';
import { AppUtils } from "../utils";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private _Router: Router
  ) { }

  getAccessToken(): any {
    const token: string | undefined = AppUtils.getAccessToken();
    return token ? token : 'Invalid Access Token';
  }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.getAccessToken();
    return req.clone({ setHeaders: { Authorization: accessToken, 'Content-Type': 'application/json' } })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
                this._Router.navigateByUrl('app/auth/logout')
                return throwError(error);
              default:
                let res = error ? error : { status: { code: 504, userMessage: "Api Not Responding. Please try Refreshing !", developerMessage: "Api Not Responding. Please try Refreshing !" } };
                return throwError(res);
            }
          } else {
            return throwError(error);
          }
        }));
  }

}
