export interface IPaginationData {
    pageIndex: number;
    maxPerPage: number;
    totalCount: number;
    sortBy?: string;
    sortOrder?: SortOrder;
}

export enum SortOrder {
    ASC = "asc",
    DESC = "desc"
}
