import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date | string | number | null | undefined, format: string = 'MMM d, y'): string | null {
    const browserLocale = navigator.language || 'en-US';
    return new DatePipe(browserLocale).transform(value, format);
  }

}
