<ng-container *ngFor="let toaster of _ToasterService.getToasters(); let index = index">
  <!--success-->
  <div *ngIf="toaster.type === toasterType.SUCCESS"
    class="d-flex justify-content-start align-items-center toasterWrap success">
    <div><i class="pi pi-check"></i></div>
    <div class="ms-3">
      <h5>Success</h5>
      <p>{{ toaster.message}}</p>
    </div>
    <div>
      <div class="close">
        <button (click)="closeToaster(toaster)" pButton type="button" class="btn btn-sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <!--info-->
  <div *ngIf="toaster.type === toasterType.INFO"
    class="d-flex justify-content-start align-items-center toasterWrap info">
    <div><i class="pi pi-info"></i></div>
    <div class="ms-3">
      <h5>Info</h5>
      <p>{{ toaster.message}}</p>
    </div>
    <div>
      <div class="close">
        <button (click)="closeToaster(toaster)" pButton type="button" class="btn btn-sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <!--warning-->
  <div *ngIf="toaster.type === toasterType.WARN"
    class="d-flex justify-content-start align-items-center toasterWrap warning">
    <div><i class="pi pi-exclamation-triangle"></i></div>
    <div class="ms-3">
      <h5>Warning</h5>
      <p>{{ toaster.message}}</p>
    </div>
    <div>
      <div class="close">
        <button (click)="closeToaster(toaster)" pButton type="button" class="btn btn-sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <!--danger-->
  <div *ngIf="toaster.type === toasterType.ERROR"
    class="d-flex justify-content-start align-items-center toasterWrap danger">
    <div><i class="bi bi-x-lg"></i></div>
    <div class="ms-3">
      <h5>Error</h5>
      <p>{{ toaster.message}}</p>
    </div>
    <div>
      <div class="close">
        <button (click)="closeToaster(toaster)" pButton type="button" class="btn btn-sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</ng-container>
