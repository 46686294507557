import { ILocalStorageUserInfo } from '../models';
import { IResponse } from './../models/response.model';

interface LocalStorageBackup {
  [key: string]: string | null;
}
export class AppUtils {
  constructor() { }

  public static getUserInfo(): ILocalStorageUserInfo | undefined {
    return localStorage.getItem('userInfo') ?
      JSON.parse(localStorage.getItem('userInfo') as string) : undefined;
  }

  public static getAccessToken(): string | undefined {
    return this.getUserInfo()?.token;
  }

  public static isLoggedIn(): boolean {
    return (localStorage && localStorage.getItem('isLoggedIn') != null && localStorage.getItem('isLoggedIn') == 'true')
  }

  public static isApiSuccess(response: IResponse<any>): boolean {
    const SuccessStatusCode: Array<string> = ['200', '201', '202'];
    if (response && response.status && response.status.code) {
      return SuccessStatusCode.includes(response.status.code);
    }
    return false;
  }

}
