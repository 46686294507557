import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public get(path: string): Observable<any> {
    return this.httpClient
      .get(path)
      .pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object): Observable<any> {
    return this.httpClient
      .put(path, JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object): Observable<any> {
    return this.httpClient
      .post(path, JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete(path)
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }
}
