import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { IAlertButtonConfig, IAlertData } from '../models';

@Injectable({ providedIn: 'root' })
export class AlertService {

  private invokeFn!: Subscriber<IAlertButtonConfig>;
  isAlertEnabled: boolean = false;
  alertData: IAlertData = {
    title: '',
    message: ''
  };
  buttonConfig: Array<IAlertButtonConfig> = [];

  public showAlertPopup(alertData: IAlertData, buttonConfig: Array<IAlertButtonConfig>): Observable<IAlertButtonConfig> {
    this.alertData = alertData;
    this.buttonConfig = buttonConfig;
    return new Observable(observer => {
      this.isAlertEnabled = true;
      this.invokeFn = observer;
    })
  }

  emitUserSelection(config: IAlertButtonConfig) {
    this.isAlertEnabled = false;
    this.invokeFn.next(config);
    this.invokeFn.complete();
  }

}
