export class Utilities {
  constructor() { }

  public static isStringEmpty(str: string): boolean {
    if (str && str.trim().length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public static isObjectContainsValidKey(obj: any, key: string): boolean {
    if (obj && obj.hasOwnProperty(key) && obj[key].toString().trim().length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public static removeObjectReference(object: any) {
    if (!object) {
      return null;
    }
    try {
      return JSON.parse(JSON.stringify(object));
    } catch (e) {
      return null;
    }
  }

  public static getArrayfromString(string: string, separator: string) {
    if (typeof (string) === 'string') {
      return string.split(separator);
    }
    return [];
  }

  public static getObjectKeys(obj: any) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj);
  }

  public static removeEmptyValuesFromArray(array: Array<any>) {
    return array.filter(function (e) { return e; });
  }

  public static convertObjectToQueryString(obj: any) {
    let str = '';
    if (obj) {
      for (const key of Object.keys(obj)) {
        if (str != '') {
          str += '&';
        }
        str += key + "=" + encodeURIComponent(obj[key]);
      }
    }
    return str;
  }

  public static compareObjects = function (o1: any, o2: any) {
    if (!o1 || !o2) {
      return false;
    }
    for (const p in o1) {
      if (o1.hasOwnProperty(p)) {
        if (o2.hasOwnProperty(p)) {
          if (o1[p] !== o2[p]) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    for (const p in o2) {
      if (o2.hasOwnProperty(p)) {
        if (o1.hasOwnProperty(p)) {
          if (o1[p] !== o2[p]) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  }

  public static isArraycontainsValue(array: Array<any>, value: any) {
    var i = array.length;
    while (i--) {
      if (array[i] === value) {
        return true;
      }
    }
    return false;
  }

  public static isEmptyObject(object: any) {
    return (JSON.stringify(object) == '{}');
  }

  public static QueryStringToJSON(url: string) {
    const pairs = url.slice(1).split('&');
    const result: any = {};
    pairs.forEach(function (pair) {
      const query: Array<any> = pair.split('=');
      result[query[0]] = decodeURIComponent(query[1] || '');
    });
    return JSON.parse(JSON.stringify(result));
  }

  public static convertCamelCaseStringToWord(camelCasedText: string) {
    return this.capitalizeFirstLetter(camelCasedText.replace(/([a-zA-Z])(?=[A-Z][a-z])/g, '$1 '));
    // return camelCasedText.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase();
  }

  public static capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
  }

  public static isValidURL(str: string): boolean {
    let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  public static removeEmptyValuesFromObject(object: any) {
    Object.getOwnPropertyNames(object).forEach(function (field) {
      if ((object[field] !== 0 && object[field] !== false) && !object[field]) {
        delete object[field];
      }
    });
    return object;
  }

  public static inputMask(regFormat: any, charLength: any) {
    let newMask = Array(charLength).fill(regFormat);
    return newMask;
  }

  public static getBooleanValue(data: any, fieldValue: string): string {
    if (data.hasOwnProperty(fieldValue)) {
      return ((data[fieldValue] === true) ? 'Yes' : 'No');
    }
    return '-';
  }

  public static getBooleanValueFromString(value: boolean): string {
    return ((value === true) ? 'Yes' : 'No');
  }

  public static getArrayFromEnum(enumValue: any, type: string): Array<any> {
    return Object.values(enumValue).filter(value => typeof value === type)
  }

  public static removeValueFromArray(arr: Array<any>, value: any): Array<any> {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  public static roundOfToTwoDecimalPlaces(value: number): number {
    return Number(parseFloat(value.toString()).toFixed(2));
  }

  public static extractFileNameFromUrl(url: string): string {
    const segments = url.split('/');
    const lastSegment = segments[segments.length - 1];
    const fileNameRegex = /([^/]+)$/; // Regex to match the entire file name with extension
    const match = lastSegment.match(fileNameRegex);
    if (match) {
      return match[1]; // The captured group containing the entire file name with extension
    } else {
      return '-'; // No match found
    }
  }
}
